import { graphql } from 'react-relay';

export const projectsPageQuery = graphql`
    query ProjectsPageQuery(
        $teamPath: String!
        $portfolioPath: String!
        $onlyMyProjects: Boolean
    ) {
        pqiUser {
            ...ProjectsEmptyState_pqiUser
        }

        team: teamByPath(path: $teamPath) {
            ...ProjectsEmptyState_team @arguments(portfolioPath: $portfolioPath)
            portfolio(path: $portfolioPath) {
                projectSurveys {
                    projectId
                    surveyNumber
                    status
                    surveyDate
                    endDate
                    closedAt
                }
                projects(onlyMyProjects: $onlyMyProjects) {
                    id
                    path
                    name
                    projectManager {
                        id
                        fullName
                        emailAddress
                    }
                    status
                    surveyCount
                    ... on Project {
                        createdAt
                    }
                }
            }
            projectCategories {
                name
                id
            }
        }
    }
`;
